<template>
    <div class="modal-wrapper"
         :class="{hide: !modals.balance}"
         v-on:click.stop="hideModal">
        <div class="modal" v-on:click.stop>
            <div class="left">
                <div class="title">{{activeAccount.demo ? tokens.balanceModal.practice : tokens.balanceModal.real}}
                    {{ tokens.balanceModal.account }} #{{activeAccount.id}}</div>
                <div class="list">
                    <div class="item">
                        <div>{{ tokens.balanceModal.balance }}</div>
                        <div class="divider"></div>
                        <div class="val-large">{{currencySign(activeAccount.currency)}}{{ (balances.balance > 0 ? balances.balance : 0).toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision}) }}</div>
                    </div>
                    <div class="item">
                        <div>{{ tokens.balanceModal.credit }}</div>
                        <div class="divider"></div>
                        <div class="val">{{currencySign(activeAccount.currency)}}{{ (balances.credit > 0 ? balances.credit : 0).toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision}) }}</div>
                    </div>
                    <div class="item">
                        <div>{{ tokens.balanceModal.invested }}</div>
                        <div class="divider"></div>
                        <div class="val">{{currencySign(activeAccount.currency)}}{{ (balances.orders * (1/conversions[activeAccount.currency])).toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision}) }}</div>
                    </div>
                    <div class="item">
                        <div>{{ balances.profit >= 0 ? tokens.balanceModal.profit : tokens.balanceModal.loss }}</div>
                        <div class="divider"></div>
                        <div class="val" :class="balances.profit > 0 ? 'green' : (balances.profit < 0 ? 'red' : '')">{{currencySign(activeAccount.currency)}}{{ (balances.profit).toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision}) }}</div>
                    </div>
                    <div class="item">
                        <div>{{ tokens.balanceModal.equity }}</div>
                        <div class="divider"></div>
                        <div class="val-large">{{currencySign(activeAccount.currency)}}{{ (balances.equity_apprx).toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision}) }}</div>
                    </div>
                    <div class="item">
                        <div>{{ tokens.balanceModal.margin }}</div>
                        <div class="divider"></div>
                        <div class="val">{{ currencySign(activeAccount.currency)+ (balances.margin * (1/conversions[activeAccount.currency])).toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision }) }}</div>
                    </div>
                    <div class="item">
                        <div>{{ tokens.balanceModal.marginLevel }}</div>
                        <div class="divider"></div>
                        <div class="val-large">{{ balances.margin !== 0 ? balances.margin_level_apprx.toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision })+'%' : '-' }}</div>
                    </div>
                    <div class="item">
                        <div>{{ tokens.balanceModal.marginFree }}</div>
                        <div class="divider"></div>
                        <div class="val-large">{{ balances.margin !== 0 ? currencySign(activeAccount.currency) + balances.free_margin_apprx.toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision }) : '-' }}</div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="title">{{ tokens.balanceModal.myAccounts }}</div>
                <div class="acc-row real"
                     v-for="account in accounts"
                     :key="account.id"
                     :class="{
                        active: activeAccount.id === account.id,
                        real: !account.demo,
                        demo: account.demo
                    }" v-on:click="changeAccount(account.id)"
                >
                    <div class="row">
                        <div class="sign">{{ currencySign(account.currency) }}</div>
                        <div class="col">
                            <span>{{ account.account_name && account.account_name != 'MAIN' ? account.account_name : account.group === 'main' ? 'STANDARD' : account.group.toUpperCase() }} {{ tokens.balanceModal.account }} #{{account.id}}</span>
                            <span class="balance">{{currencySign(account.currency)}}{{ account.balance.toLocaleString(undefined, { minimumFractionDigits: account.precision, maximumFractionDigits: account.precision}) }}</span>
                        </div>
                    </div>
                    <div class="button" :class="{hide: activeAccount.id === account.id}">{{ tokens.fullScreenAccounts.makeActive }}</div>
                    <span class="green acc-active" :class="{hide: activeAccount.id !== account.id}">{{ tokens.fullScreenAccounts.active }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ACCOUNT_ACTIVE, MODAL} from "@/store/mutations.type";
import {mapGetters} from "vuex";
import {currencySign} from "@/common/helpers";
import {DEMO_ACCOUNT_RESET} from "@/store/actions.type";

export default {
    name: "BalanceModal",
    methods: {
        hideModal() {
            this.$store.commit(MODAL, {
                balance: false
            })
        },
        changeAccount(id) {
            this.$store.commit(ACCOUNT_ACTIVE, id)
        },
        resetDemo() {
            this.$store.dispatch(DEMO_ACCOUNT_RESET)
        },
        currencySign
    },
    computed: {
        ...mapGetters(['modals', 'activeAccount', 'accounts', 'symbols', 'tokens','conversions']),
        balances() {
            let b = {
                balance: 0,
                orders: 0,
                profit: 0,
                credit: 0,
                margin: 0,
                // equity: 0,
                margin_level_apprx: 0,
                free_margin_apprx: 0,
                equity_apprx: 0
            }

            if (!this.activeAccount.id) {
                return b
            }

            b.balance = this.activeAccount.balance
            b.credit = this.activeAccount.credit

            let marginBySymbol = {}

            for(let n in this.activeAccount.orders) {
                let o = this.activeAccount.orders[n]

                o.easy_amount = o.easy_amount || o.margin

                b.orders += o.easy_amount

                if (marginBySymbol[o.symbol] === undefined) {
                    marginBySymbol[o.symbol] = 0
                }

                marginBySymbol[o.symbol] += o.type % 2 ? -o.margin : o.margin

                if (!this.symbols[o.symbol]) {
                    continue
                }

                b.profit += o.type < 2 ? (o.point * (o.type ? o.price_open - this.symbols[o.symbol].quote.ask : this.symbols[o.symbol].quote.bid - o.price_open) * Math.pow(10, this.symbols[o.symbol].precision) + o.swap + o.commission) : 0
            }

            for(let n in marginBySymbol) {
                b.margin += Math.abs(marginBySymbol[n])
            }

            b.equity = b.balance + b.credit + b.profit
            b.margin_level_apprx = this.activeAccount.balance + this.activeAccount.credit + b.profit !== 0 && b.margin ? (100 * (this.activeAccount.balance + this.activeAccount.credit + b.profit) / b.margin).toFixed(2) : ''
            b.free_margin_apprx = this.activeAccount.balance + this.activeAccount.credit + b.profit - b.margin
            b.equity_apprx =  b.equity

            return b
        }
    }
}
</script>

<style scoped>
    .modal-wrapper {
        background: transparent;
    }

    .modal {
        position: fixed;
        z-index: 2;
        right: 0;
        top: 81px;
        width: 650px;
        display: flex;
        align-items: stretch;
    }

    .left {
        flex: 0 0 250px;
        padding: 15px 25px;
        background: var(--background-dark-mid);
    }

    .right {
        flex: 1 1 auto;
        padding: 15px;
    }

    .title {
        color: var(--white-to-black);
        font-weight: 600;
        margin-bottom: 20px;
    }

    .list {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
    }

    .list .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 6px 0;
        font-weight: 400;
        font-size: 1.1em;
    }

    .list .item .divider {
        flex: 1 1 auto;
        margin: 0 10px;
        height: 1px;
        border-bottom: 1px solid var(--border);
    }

    .list .item .val {
        font-weight: 600;
    }

    .list .item .val-large {
        font-weight: 600;
        font-size: 1.2em;
        color: var(--white-to-black)
    }

    .right .title {
        padding: 0 15px;
    }

    .acc-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        cursor: pointer;
        transition: background-color ease-in-out 0.3s;
        margin-bottom: 3px;
    }

    .acc-row:hover, .acc-row.active {
        background-color: var(--background-dark-mid);
    }

    .acc-row .col {
        font-weight: 600;
        color: var(--white-to-black)
    }

    .acc-row .balance {
        margin-top: 4px;
    }

    .real .balance {
        color: var(--green)
    }

    .demo .balance {
        color: var(--brand)
    }

    .acc-row .sign {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white);
        font-weight: 400;
        font-size: 1.2em;
        margin-right: 15px;
    }

    .demo .sign {
        background-color: var(--brand);
    }

    .real .sign {
        background-color: var(--green);
    }

    .acc-row .button {
        color: var(--white-to-black);
        font-size: 1.1em;
        font-weight: 400;
        width: 120px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--background);
        border-radius: 2px;
    }

    .mobile .modal {
        top: 90px
    }

    @media screen and (orientation: portrait) {
        .mobile .modal {
            flex-direction: column;
            width: 100%;
            height: calc(100vh - 66px);
        }
    }
</style>
